/* -- SEARCHBOX -- */
/* ---------------------------------------------- */


.searchbox {
	width: auto;



	// Search input / .form-control
	// ----------------------------------------------
	.searchbox__input {
		padding-right: calc( 1ex + #{ ( $btn-padding-x * 2 )} );
		transition: width $searchbox-transition $searchbox-transition-timing $searchbox-transition-delay, background-color $searchbox-transition $searchbox-transition-timing $searchbox-transition-delay;


		&:focus {
			transition: width $searchbox-transition $searchbox-transition-timing $searchbox-transition-delay, background-color $searchbox-transition $searchbox-transition-timing $searchbox-transition-delay
		}


		&.bg-transparent {
			background-color: transparent !important;
			border: 0;
			box-shadow: none !important;
			color: inherit;


			&:focus {
				background-color: $searchbox-focus-bg !important;
			}
		}
	}





	// Override Bootstrap's border-radius for input groups
	// ----------------------------------------------
	&.input-group:not( .has-validation ) > .searchbox__input:not( :last-child ):not( .dropdown-toggle ):not( .dropdown-menu ) {
		border-radius: $input-border-radius;
	}





	// Search button
	// ----------------------------------------------
	.searchbox__btn {
		font-size: 1rem;
		height: 100%;
		position: absolute;
		right: 0;
		top: 0;
		transition: opacity $searchbox-transition $searchbox-transition-timing $searchbox-transition-delay, visibility $searchbox-transition $searchbox-transition-timing $searchbox-transition-delay;
		z-index: 5;
	}



	// Auto expand the search box while focusing.
	// ----------------------------------------------
	&.searchbox--auto-expand .searchbox__input {
		width: $searchbox-collapsed-width;
		&:focus { width: $searchbox-expanded-width }
	}





	// Only show the search button while the input is in focus.
    // ----------------------------------------------
	&.searchbox--hide-btn {
		.searchbox__btn {
			opacity: 0;
			visibility: hidden;
		}


		.searchbox__input:focus {
            ~ .searchbox__btn, ~ div > .searchbox__btn {
                opacity: 1;
                transition: opacity $searchbox-transition ease, visibility $searchbox-transition ease;
                visibility: visible;
            }
        }
	}
}