/* -- CONTENT VIEW -- */
/* ---------------------------------------------- */

// Content full body
// ----------------------------------------------
.content-full-page {
    position: fixed;
    inset: 0;
    z-index: 999999;
    border-radius: 0 !important;
}
