/* -- SIDEBAR UNITE -- */
/* ---------------------------------------------- */


.sb--unite {
	.sidebar {
		background-color: $sidebar-unite-bg;
		color: $sidebar-unite-color;


		%active-color {
			color: $sidebar-unite-active-color;
		}


		:where( h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 ) {
			@extend %active-color;
		}


        .text-muted {
            color: rgba( $sidebar-unite-color, .85) !important;
        }


        .btn-primary, .form-switch .form-check-input:checked {
            background-color: tint-color( $primary, 20% );
            border-color: tint-color( $primary, 20% );
        }


        .btn-link {
            color: tint-color( $link-color, 75% );
            &:hover, &:focus, &:active, &.active {
                color: tint-color( $link-color, 45% );
            }
        }



        // Nav tabs with border at the bottom
        // ----------------------------------------------
		.nav-callout {
			border-color: $sidebar-unite-border-color;

			.nav-link.active {
				box-shadow: inset 0 #{ 0 - $callout-width } $sidebar-unite-active-color;
			}
		}



        // Nav links
        // ----------------------------------------------
		.nav-link{
            color: $sidebar-unite-color;

            i, .nav-icon {
                color: rgba( $sidebar-unite-color, .65 ) !important;
            }

			&.active, &:hover, &:focus {
				@extend %active-color;
			}
		}



        // List group
        // ----------------------------------------------
		.list-group-item-action {
            @at-root {
                #{&}, .sb--unite .sidebar .list-group-item {
                    color: $sidebar-unite-color;
                }
            }
            &:hover, &:focus {
			    background-color: $sidebar-unite-active-bg;
			    color: $sidebar-unite-active-color;
            }
		}
	}
}