/* -- MAINNAV MENU LINK -- */
/* ---------------------------------------------- */

.mainnav__caption {
    color: $mainnav-caption-color;
}



// Menu Links active and icons
// ----------------------------------------------
.mainnav__inner {
    .mainnav__menu {
        .nav-link {
            padding-block: .59rem;
        }
        > .nav-item > .nav-link {
            display: flex;

            &.active {
                background-color: $mainnav-submenu-active-bg;
                border-radius: $mainnav-submenu-border-radius;
                box-shadow: $mainnav-submenu-active-shadow;
                color: $mainnav-submenu-active-color;
                font-weight: bold;
                i, .nav-icon {
                    color: inherit !important;
                }
            }

            &:not(.collapsed):not(.active) {
                color: $mainnav-link-active;
            }
        }
    }

    @at-root {
        .root #{&} {
            .nav-link {
                color: $mainnav-link-color;

                &.disabled {
                    opacity: .4;
                }

                &:hover {
                    color: $mainnav-link-hover;
                }

                &.active ~ .nav .active {
                    color: $mainnav-link-active;
                    &:hover {
                        color: $mainnav-link-hover;
                    }
                }

                i, .nav-icon {
                    color: $mainnav-icon-color !important;
                }
            }
        }
    }
}





// Collapsed submenus and hidden contents
// ----------------------------------------------
.mainnav__menu {
	.mininav-content  {
		flex-direction: column;
		flex-wrap: nowrap;
	}


	.mininav-toggle {
		align-items: center;
		display: flex;


		> .nav-label, .nav-icon, i {
			pointer-events: none;
		}


		.nav-label {
			display: inline-block;
		}
	}





	// Submenu chevron icons
	// ----------------------------------------------
	.has-sub > .mininav-toggle {
		&:not(.has-badge):after {
			border-style: solid;
			border-width: 0.15em 0.15em 0 0;
			content: '';
			display: inline-block;
			height: 0.55em;
			margin-left: auto;
			transform: rotate(135deg);
			transition: transform $transition-duration / 2;
			width: 0.55em;
		}


		&.collapsed:after {
			transform: rotate(45deg);
		}
	}
}





// Disables annoying transitions in the open submenu when the page finishes loading.
// ----------------------------------------------
.root:not(.mn--min) .mainnav__menu .has-sub.open {
    > .mininav-content {
        display: block;
        height: auto !important;
    }


    > .mininav-toggle:after {
        transition: none !important;
        transform: rotate(135deg) !important;
    }
}





// Submenu Indent
// ----------------------------------------------
@include media-breakpoint-down($mainnav-min-available-at) {
    .root .mininav-content {
        position: static !important;
        transform: none !important;
    }


    .root .mainnav__menu > .nav-item {
        &.has-sub .has-sub > .mininav-content {
            > .nav-item > .nav-link {
                padding-inline: .75em;
            }
            border-inline-start: 1px dashed $mainnav-submenu-dashed-color;
            border-radius: $mainnav-submenu-border-radius;
            margin-inline-start: calc( #{ $mainnav-submenu-indent-lv2 - .5em } + #{ $nav-link-padding-x } ) !important;
            padding-inline-start: .5rem;
        }


        &.has-sub > .mininav-content {
            border-inline-start: 1px dashed $mainnav-submenu-dashed-color;
            margin-inline-start: $mainnav-submenu-dashed-margin !important;
        }


        &.has-sub > .mininav-content > .nav-item > .nav-link {
            padding-inline-start: calc( #{ $mainnav-submenu-indent - $mainnav-submenu-dashed-margin } + #{ $nav-link-padding-x } );
        }
    }
}


@include media-breakpoint-up($mainnav-min-available-at) {
    .root:not( .mn--min ) .mininav-content {
        position: static !important;
        transform: none !important;
    }


    .root:not( .mn--min ) .mainnav__menu > .nav-item {
        &.has-sub .has-sub > .mininav-content {
            > .nav-item > .nav-link {
                padding-inline: .75em;
            }
            border-inline-start: 1px dashed $mainnav-submenu-dashed-color;
            margin-inline-start: calc( #{ $mainnav-submenu-indent-lv2 - .5em } + #{ $nav-link-padding-x } );
            padding-inline-start: .5rem;
        }


        &.has-sub > .mininav-content {
            border-inline-start: 1px dashed $mainnav-submenu-dashed-color;
            margin-inline-start: $mainnav-submenu-dashed-margin;
        }


        &.has-sub > .mininav-content > .nav-item > .nav-link {
            padding-inline-start: calc( #{ $mainnav-submenu-indent - $mainnav-submenu-dashed-margin } + #{ $nav-link-padding-x } );
        }
    }
}