/* -- OBJECT FIT -- */
/* ---------------------------------------------- */

.object-fill {
    object-fit: fill;
}

.object-contain {
    object-fit: contain;
}

.object-cover {
    object-fit: cover;
}

.object-none {
    object-fit: none;
}

.object-scale-down {
    object-fit: scale-down;
}