/* -- MAIN NAVIGATION - OFF CANVAS / REVEAL MODE -- */
/* ---------------------------------------------- */


.mn--reveal {

	// Reveal navigation
	// ----------------------------------------------
	.mainnav {
		pointer-events: none;
		transform: translateX(0) !important;
		z-index: 5 !important;
	}





	// Set the default z-index
	// ----------------------------------------------
	.header {
		z-index: 12;
	}

	.content, .sidebar {
		z-index: 10;
	}

	&.sb--pinned .sidebar {
		z-index: 11;
	}





	// Transition
	// ----------------------------------------------
	@at-root {
		&, &.mn--show {
			.content, .header {
				transition: transform $mainnav-reveal-transition $mainnav-reveal-transition-timing;
			}
		}
	}





	// Off-Canvas show state
	// ----------------------------------------------
	&.mn--show {
		.mainnav {
			pointer-events: visible;
		}


		&:before, .content, .header {
			transform: translateX( $mainnav-max-width );
		}


		&:before {
			transition: opacity $mainnav-reveal-transition, transform $mainnav-reveal-transition !important;
		}


		&.sb--show.sb--pinned .sidebar {
			transform: translateX( #{ $mainnav-max-width });
		}
	}
}