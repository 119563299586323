// DEFAULT PALETTE VARIABLES
// ----------------------------------------------

// Color variables
// ----------------------------------------------
$white        : #fff !default;
$black        : #373c43 !default;
$gray         : #e1e7f0 !default;
$blue         : #03a9f4 !default;
$indigo       : #25476a !default;
$purple       : #ab47bc !default;
$pink         : #f06292 !default;
$red          : #df5645 !default;
$orange       : #fa9f1b !default;
$yellow       : #ffe405 !default;
$green        : #9FCC2E !default;
$teal         : #26a69a !default;
$cyan         : #0dcaf0 !default;



// Theme colors
// ----------------------------------------------
$secondary    : $teal !default;
$success      : $green !default;
$info         : $blue !default;
$warning      : $orange !default;
$danger       : $red !default;
$light        : $gray !default;
$dark         : $black !default;