/* -- SIDEBAR - STUCK -- */
/* ---------------------------------------------- */

// The sidebar will stay stuck in place until you click the close button.
// ----------------------------------------------
.sidebar__stuck {
	display: none;
}



.sb--stuck {
	&.sb--show {

        // Disable the backdrop
		&:before {
			opacity: 0;
			pointer-events: none;
			visibility: hidden;
		}

        // Add a shadow to the sidebar.
		.sidebar {
			box-shadow: $sidebar-no-backdrop-shadow;
		}
	}


    // Display the content when the sidebar shows up.
	.sidebar__stuck {
		display: flex;
	}
}