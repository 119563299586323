// DEFAULT COLORS
// ----------------------------------------------

// Import default palette
// ----------------------------------------------
@import "default-palette";



// Import default scheme
// ----------------------------------------------
@import "default-scheme";